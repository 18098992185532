import React, { useState, useEffect } from "react";
import { Form, Input, Button, Result, Spin, Alert } from "antd";
import { RightOutlined, LoadingOutlined } from "@ant-design/icons";
import { CountdownCircleTimer } from "react-countdown-circle-timer";
import SelectPaymentMethodModal from "../../../Shared/SelectPaymentMethodModal";
import { order_post } from "api/orders";
import { payment_mbway_post } from "api/payments";
import mbwayconfirm from "../../../../assets/images/confirm_on_mbway.png";
import { IsNullUndefinedEmpty } from "utils/helper";
import { utmParams } from "utils/helper";
const { utm_source, utm_medium, utm_campaign, utm_term, utm_content } = utmParams();

const renderTime = ({ remainingTime }) => {
  try {
    if (remainingTime === 0) {
      return <div className="timer">Oops</div>;
    }
    // const minutes = Math.floor(remainingTime / 60);
    // const seconds = remainingTime % 60;

    return (
      <div className="timer">
        <div className="text">Restam</div>
        <div className="value">{remainingTime}</div>
        <div className="text">segundos</div>
      </div>
    );
  } catch (error) {
    //console.log("error", error);
    return <></>;
  }
};

/***********************************************************************************************
 ***********************************************************************************************
 ***********************************************************************************************
 * ##: APP
 ***********************************************************************************************
 ***********************************************************************************************
 **********************************************************************************************/
const MBWay = ({ productData, data, actionHandler, step }) => {
  const [formMBWay] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [countdownIsPlaying, setCountdownIsPlaying] = useState(false);
  const [showPhoneInput, setShowPhoneInput] = useState(true);
  const [selectPaymentMethod, setSelectPaymentMethod] = useState({ visible: false });
  const [mbwayPhoneNumber, setMbwayPhoneNumber] = useState("");
  const [validPhoneNumber, setValidPhoneNumber] = useState(false);
  const [paymentDataResponse, setPaymentDataResponse] = useState({});
  const [waitForPayment, setWaitForPayment] = useState(false);
  const [paymentTimeout, setPaymentTimeout] = useState(false);

  const [errorFetching, setErrorFetching] = useState(false);
  const [paid, setPaid] = useState(false);

  useEffect(() => {
    const status = data?.status?.action;
    setPaid(status === "paid");

    if (mbwayPhoneNumber === "") {
      if (IsNullUndefinedEmpty(data?.identification?.phone) === false) {
        setMbwayPhoneNumber(data.identification.phone);
        formMBWay.setFieldsValue({ phone: data.identification.phone });
        formMBWay.validateFields(["phone"]);
      }
    }
  }, [data]);

  const createOrder = (payload) => {
    return new Promise((resolve) => {
      try {
        order_post(payload)
          .then((res) => resolve({ status: true, data: res }))
          .catch((err) => resolve({ status: false, data: err }));
      } catch (error) {
        resolve({ status: false, data: error });
      }
    });
  };

  const cleanMBWAYPhoneNumber = (mbwayPhone) => {
    try {
      // return phone
      //   .replace(/ /gi, "")
      //   .replace(/\-/gi, "")
      //   .replace(/\+351/gi, "")
      //   .replace(/00351/gi, "")
      //   .replace(/351/gi, "")
      //   .trim();

      // Fix phone number
      // let mbwayPhone = payload.phone;

      if (mbwayPhone.startsWith("+351")) {
        mbwayPhone = mbwayPhone.substring(4);
      }

      if (mbwayPhone.startsWith("00351")) {
        mbwayPhone = mbwayPhone.substring(5);
      }

      if (mbwayPhone.startsWith("351")) {
        mbwayPhone = mbwayPhone.substring(3);
      }

      mbwayPhone = mbwayPhone.replace(/ /gi, "").replace(/\-/gi, "").trim();

      return mbwayPhone;

      // Error handling
    } catch (error) {
      return mbwayPhone;
    }
  };

  const getMBWayReference = async () => {
    if (data?.identification?.email === undefined || data?.identification?.email === null) {
      return;
    }
    setLoading(true);
    setShowPhoneInput(false);
    setCountdownIsPlaying(true);
    setPaymentTimeout(false);
    const cleanedMBWAYPhoneNumber = cleanMBWAYPhoneNumber(mbwayPhoneNumber);
    formMBWay.setFieldsValue({ phone: cleanedMBWAYPhoneNumber });
    const payload = {
      buyer: data.identification,
      products: data.purchase_products,
      purchase_id: data.purchase_id,
      main_product: data.main_product,
      coupon: data.coupon,
      phone: cleanedMBWAYPhoneNumber,
      pathname: window.location.pathname,
      origin: window.location.origin,
      utm_source,
      utm_medium,
      utm_campaign,
      utm_term,
      utm_content,
    };

    // Disable hability to change quantity
    actionHandler("qty_locked", true);

    const order = await createOrder({ ...payload, method: "mbway" });
    if (!order.status) {
      setLoading(false);
      setErrorFetching(true);
      return;
    }

    payment_mbway_post(payload)
      .then((response) => {
        setPaymentDataResponse(response.data);
        setErrorFetching(false);
        setWaitForPayment(true);

        actionHandler("update_status", {
          action: "wait-for-payment",
          data: {
            method_id: "MBWAY",
            entity: response.data.entity,
            reference: response.data.reference,
            phone: mbwayPhoneNumber,
          },
        });
      })
      .catch((error) => {
        setErrorFetching(true);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const ShowCountdown = () => {
    if (waitForPayment && !errorFetching) {
      return (
        <CountdownCircleTimer
          strokeWidth={4}
          isPlaying={countdownIsPlaying}
          size={120}
          duration={240}
          colors={["#59cf15", "#cfc615", "#cfa115", "#cf2e15"]}
          colorsTime={[240, 90, 60, 0]}
          onComplete={() => {
            setWaitForPayment(false);
            setCountdownIsPlaying(false);
            setPaymentTimeout(true);
            actionHandler("update_status", {
              action: "checkout",
              data: {},
            });
          }}
        >
          {renderTime}
        </CountdownCircleTimer>
      );
    }
    return <></>;
  };

  return (
    <div className="mbway-container">
      <div className="payment-data-container">
        {paid === false && (
          <Spin spinning={loading} tip="A efetuar o pedido no MB WAY...">
            {loading && <div className="loading"></div>}
            {showPhoneInput && (
              <>
                <div className="title">Indica o número de telemóvel com a aplicação MB WAY</div>

                <Form className="frm-mbway" layout="vertical" form={formMBWay}>
                  <Form.Item
                    name="phone"
                    validateStatus={validPhoneNumber ? "success" : "warning"}
                    rules={[
                      {
                        required: true,
                        message: "Indica o número de telemóvel configurado com o MB WAY",
                      },
                      {
                        validator(rule, value) {
                          if (rule.field !== "phone") return Promise.resolve();

                          value = value.replace(/[^0-9]+/g, "").replace(/ /g, "");
                          let valid = /(9[1236]\d) ?(\d{3}) ?(\d{3})/.test(value);

                          if (value.toString().length > 9) {
                            setValidPhoneNumber(false);
                            return Promise.reject("O número de telemóvel não pode ter mais de 9 dígitos.");
                          }

                          if (!valid) {
                            setValidPhoneNumber(false);

                            return Promise.reject(
                              value.toString().length === 9 ? "O número de telemóvel é inválido." : ""
                            );
                          }

                          setValidPhoneNumber(true);

                          return Promise.resolve("Número de telemóvel válido!");
                        },
                      },
                    ]}
                    hasFeedback
                  >
                    <Input
                      className={`phoneinput ${validPhoneNumber ? "is-valid" : "not-valid"}`}
                      placeholder="Número de telemóvel"
                      // onChange={(e) => setMbwayPhoneNumber(e.target.value)}
                      onChange={(e) => {
                        const cleanPhone = cleanMBWAYPhoneNumber(e.target.value);
                        setMbwayPhoneNumber(cleanPhone);
                        formMBWay.setFieldsValue({ phone: cleanPhone });
                      }}
                    />
                  </Form.Item>
                  <Form.Item>
                    <Button
                      className="submit-button"
                      disabled={!validPhoneNumber}
                      onClick={() => getMBWayReference()}
                    >
                      Confirmar número de telemóvel <RightOutlined />
                    </Button>
                  </Form.Item>
                </Form>
              </>
            )}

            {paymentTimeout && !loading && (
              <div className="error">
                <Alert
                  message="Oops, confirmação não efetuada."
                  description="O pagamento não foi confirmado em tempo útil. Tenta novamente, ou escolhe outro método de pagamento."
                  type="warning"
                  showIcon
                />
                <div style={{ textAlign: "center" }}>
                  <Button type="primary" onClick={() => getMBWayReference()}>
                    Tentar novamente
                  </Button>
                </div>
                <div style={{ textAlign: "center" }}>
                  <Button type="link" onClick={() => setSelectPaymentMethod({ visible: true })}>
                    Selecionar outro método de pagamento
                  </Button>
                </div>
              </div>
            )}

            {errorFetching && !loading && (
              <div className="error">
                <Alert
                  message="Oops, ocorreu um erro ao efetuar o pedido de MB WAY."
                  description="Não foi possível efetuar o pedido de MB WAY para a tua compra. Tenta novamente dentro de alguns segundos."
                  type="error"
                  showIcon
                />
                <div style={{ textAlign: "center" }}>
                  <Button type="primary" onClick={() => getMBWayReference()}>
                    Tentar novamente
                  </Button>
                </div>
                <div style={{ textAlign: "center" }}>
                  <Button type="link" onClick={() => setSelectPaymentMethod({ visible: true })}>
                    Selecionar outro método de pagamento
                  </Button>
                </div>
              </div>
            )}

            {waitForPayment && !errorFetching && (
              <div>
                <div className="payment-loading-title">Confirma o pagamento na aplicação MB WAY.</div>
                <div className="payment-loading-sub-title">
                  Tens de abrir a aplicação MB WAY no teu telemóvel e confirmar o pagamento.
                </div>
                <div style={{ display: "flex", justifyContent: "space-evenly", alignItems: "center" }}>
                  <div className="payment-loading-image">
                    <img
                      height={130}
                      src={mbwayconfirm}
                      alt="Confirma por favor o pagamento na aplicação MBWay do teu telemóvel."
                    />
                  </div>
                  <ShowCountdown />
                </div>
                <div className="payment-loading">
                  <Alert
                    message={
                      <div className="payment-loading-alert">
                        <LoadingOutlined /> A aguardar confirmação...
                      </div>
                    }
                    type="warning"
                  />
                </div>
              </div>
            )}
          </Spin>
        )}
      </div>

      {paid === true && (
        <Result
          status="success"
          title="Pagamento Concluído"
          subTitle="Parabéns, a tua compra foi efetuada com sucesso!"
        />
      )}
      <SelectPaymentMethodModal
        data={data}
        actionHandler={actionHandler}
        visible={selectPaymentMethod.visible}
        close={() => setSelectPaymentMethod({ visible: false })}
      />
    </div>
  );
};

export default MBWay;
