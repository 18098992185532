import React, { useState } from "react";
import { Avatar, Typography, Divider, Modal, Button, Drawer } from "antd";

const { Text, Paragraph } = Typography;

const Producer = ({ data, isMobile }) => {
  const [showProducerModal, setShowProducerModal] = useState(false);

  const Initials = ({ name }) => {
    name = name.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
    const short_name = name.replace(/([a-z]+) .* ([a-z]+)/i, "$1 $2");
    return short_name.match(/\b\w/g).join("");
  };

  return (
    <>
      <Divider style={{ marginTop: 15, marginBottom: 15, paddingTop: isMobile ? 15 : 0 }} dashed />
      <div className="producer-container">
        <Avatar size="small" style={{ backgroundColor: "#87d068" }}>
          <Initials name={data?.producer?.name} />
        </Avatar>

        <div className="producer">
          <div>
            <Text className="producer-name">
              <strong>Produtor:</strong>{" "}
              <span onClick={() => setShowProducerModal(true)} className="clickable_hover">
                {data?.producer?.name}
              </span>
            </Text>
          </div>
        </div>
      </div>
      {!isMobile && (
        <Modal
          visible={showProducerModal}
          title="Contacto do Produtor"
          onCancel={() => setShowProducerModal(false)}
          footer={[
            <Button key="back" onClick={() => setShowProducerModal(false)}>
              Fechar
            </Button>,
          ]}
        >
          <strong>Nome: </strong>
          <Paragraph level={5}>{data?.producer?.name}</Paragraph>
          <strong>Endereço de e-mail: </strong>
          <Paragraph level={5} copyable={{ tooltips: ["Copiar", "Copiado"] }}>
            {data?.producer?.email}
          </Paragraph>
        </Modal>
      )}
      {isMobile && (
        <Drawer
          title="Contacto do Produtor"
          placement="bottom"
          closable={true}
          onClose={() => setShowProducerModal(false)}
          visible={showProducerModal}
          footer={
            <div style={{ textAlign: "center" }}>
              <Button type="primary" key="back" onClick={() => setShowProducerModal(false)}>
                Fechar
              </Button>
              ,
            </div>
          }
        >
          <strong>Nome: </strong>
          <Paragraph level={5}>{data?.producer?.name}</Paragraph>
          <strong>Endereço de e-mail: </strong>
          <Paragraph level={5} copyable={{ tooltips: ["Copiar", "Copiado"] }}>
            {data?.producer?.email}
          </Paragraph>
        </Drawer>
      )}
    </>
  );
};

export default Producer;
