import { formatCurrency, IsNullUndefinedEmpty } from "utils/helper";

export const totalPayment = (selectedOffer) => formatCurrency(selectedOffer?.price * selectedOffer?.n_installments);

export const totalPaymentTooltip = (selectedOffer) => {
  if (selectedOffer.price_method === "installments") {
    return `Vais pagar ${formatCurrency(selectedOffer?.price)} agora, mais ${selectedOffer?.n_installments - 1} ${
      selectedOffer?.n_installments - 1 > 1 ? "prestações" : "prestação"
    } de ${formatCurrency(selectedOffer?.price)}. O valor total a pagar é de ${totalPayment(selectedOffer)}.`;
  }
  if (selectedOffer.price_method === "recurrence") {
    return `As recorrências terminam após um máximo de ${selectedOffer?.recurrence_max_payments} pagamentos.`;
  }
};

const productPrice = (product, mainProduct) => {
  if (IsNullUndefinedEmpty(product)) return <></>;

  let qty = 1;

  if (product?.product_id === mainProduct?.product_id) {
    qty = mainProduct?.qty || 1;
    const total = product?.price * qty;
    return formatCurrency(total);
  }

  return formatCurrency(product?.price);
};

export const showProductsListInfo = (product, mainProduct) => {
  if (IsNullUndefinedEmpty(product)) return <></>;

  if (product?.type === "bump") {
    return <div className="n_intallments">{productPrice(product, mainProduct)}</div>;
  }

  if (product?.price_method === "recurrence") return showProductsListRecurrenceInfo(product);
  if (product?.price_method === "installments") return showProductsListInstallmentsInfo(product);
  return <>{productPrice(product, mainProduct)}</>;
};

const showProductsListInstallmentsInfo = (product) => {
  if (IsNullUndefinedEmpty(product)) return <></>;
  if (product?.n_installments === 1) return <>{productPrice(product)}</>;

  return (
    <div className="n_intallments">
      {product.n_installments} x {productPrice(product)}
    </div>
  );
};

const showProductsListRecurrenceInfo = (product) => {
   let recurrenceFrequencySingular = "mês";

  if (product.recurrence_frequency) {
    recurrenceFrequencySingular = product.recurrence_frequency === "month" ? "mês" : "ano";
  }

  return (
    <div className="n_intallments">
      {productPrice(product)} / {recurrenceFrequencySingular}
    </div>
  );
};

export const getOfferData = (offer_id, productData) =>
  productData?.offers.find((offer) => offer.offer_id === offer_id);

export const totalPriceInstallmentsInfo = (mainProduct, otherProducts) => {
  if (otherProducts?.length > 0) return "";
  if (mainProduct?.n_installments > 1) return `${mainProduct?.n_installments} x `;
  return "";
};
